import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const rawData = [
	["17-09-03", 72.1282, 0.6154],
	["17-09-10", 68.3846, 0.5897],
	["17-09-17", 63.5897, 0.6154],
	["17-09-24", 62.2821, 0.641],
	["17-10-01", 62.8974, 0.641],
	["17-10-08", 73.4872, 0.6154],
	["17-10-15", 72.8205, 0.641],
	["17-10-22", 67.7692, 0.641],
	["17-10-29", 69.4872, 0.6154],
	["17-11-05", 76.6667, 0.5897],
	["17-11-12", 72.4872, 0.5897],
	["17-11-19", 62.2308, 0.5897],
	["17-11-26", 52.7436, 0.5897],
	["17-12-03", 56.7692, 0.641],
	["17-12-10", 45.9487, 0.5128],
	["17-12-17", 64.8718, 0.5641],
	["17-12-24", 59.1282, 0.5897],
	["17-12-31", 51.4103, 0.5897],
	["18-01-07", 53.0256, 0.6154],
	["18-01-14", 64.6923, 0.6667],
	["18-01-21", 60.8718, 0.6154],
	["18-01-28", 52.2564, 0.6154],
	["18-02-04", 55.6154, 0.6667],
	["18-02-11", 48.8462, 0.5385],
	["18-02-18", 59.1795, 0.6154],
	["18-02-25", 64.8205, 0.6667],
	["18-03-04", 60.5641, 0.6154],
	["18-03-11", 82.359, 0.6923],
	["18-03-18", 65.0513, 0.5897],
	["18-03-25", 70.0513, 0.6154],
	["18-04-01", 62.0256, 0.5897],
	["18-04-08", 71.359, 0.641],
	["18-04-15", 64.0769, 0.641],
	["18-04-22", 55.5128, 0.641],
	["18-04-29", 59.8205, 0.6154],
	["18-05-06", 62.641, 0.6154],
	["18-05-13", 61.4103, 0.5897],
	["18-05-20", 66.6667, 0.5641],
	["18-05-27", 62.3846, 0.641],
	["18-06-03", 71.6154, 0.641],
	["18-06-10", 89.5897, 0.641],
	["18-06-17", 79, 0.6154],
	["18-06-24", 61.5128, 0.641],
	["18-07-01", 64.641, 0.6154],
	["18-07-08", 68.1282, 0.641],
	["18-07-15", 61.5385, 0.6154],
	["18-07-22", 53.8462, 0.641],
	["18-07-29", 52.6667, 0.641],
	["18-08-05", 56.5897, 0.6667],
	["18-08-12", 58.0256, 0.641],
	["18-08-19", 48.7179, 0.5897],
	["18-08-26", 50.5128, 0.6154],
	["18-09-02", 54.5897, 0.6667],
	["18-09-09", 58.8718, 0.5641],
	["18-09-16", 64.8205, 0.6667],
	["18-09-23", 68.5641, 0.6923],
	["18-09-30", 71.7179, 0.6667],
	["18-10-07", 69.8718, 0.5897],
	["18-10-14", 59.0256, 0.6154],
	["18-10-21", 57.3846, 0.6154],
	["18-10-28", 61.2564, 0.5897],
	["18-11-04", 49.6923, 0.5897],
	["18-11-11", 56.2564, 0.5641],
	["18-11-18", 56.2564, 0.5641],
	["18-11-25", 56.1026, 0.5385],
	["18-12-02", 50.2051, 0.5385],
	["18-12-09", 71.4359, 0.5385],
	["18-12-16", 63.641, 0.5385],
	["18-12-23", 58.4359, 0.5128],
	["18-12-30", 53.9744, 0.5641],
	["19-01-06", 77.4103, 0.6154],
	["19-01-13", 68.1026, 0.5897],
	["19-01-20", 62.8718, 0.6667],
	["19-01-27", 57.4615, 0.5897],
	["19-02-03", 67.1282, 0.641],
	["19-02-10", 62.2821, 0.6154],
	["19-02-17", 66.8718, 0.5897],
	["19-02-24", 76.6667, 0.6923],
	["19-03-03", 71.641, 0.6154],
	["19-03-10", 70.6923, 0.5897],
	["19-03-17", 67.4103, 0.5641],
	["19-03-24", 67.359, 0.641],
	["19-03-31", 64.8462, 0.641],
	["19-04-07", 66.2051, 0.6154],
	["19-04-14", 61.3846, 0.5641],
	["19-04-21", 64.3333, 0.5641],
	["19-04-28", 63.4359, 0.5641],
	["19-05-05", 66.8718, 0.5641],
	["19-05-12", 61.641, 0.5641],
	["19-05-19", 55.8205, 0.5128],
	["19-05-26", 51.7179, 0.5385],
	["19-06-02", 50.9231, 0.5897],
	["19-06-09", 66.7436, 0.5641],
	["19-06-16", 64.0769, 0.5897],
	["19-06-23", 59.9487, 0.5385],
	["19-06-30", 69.9231, 0.6154],
	["19-07-07", 70.9231, 0.6154],
	["19-07-14", 63.5385, 0.5897],
	["19-07-21", 55.9744, 0.5897],
	["19-07-28", 49.0513, 0.5897],
	["19-08-04", 60.9231, 0.6154],
	["19-08-11", 46.5385, 0.5897],
	["19-08-18", 50.9744, 0.6154],
	["19-08-25", 58, 0.6154],
	["19-09-01", 60.4615, 0.5897],
	["19-09-08", 60.4103, 0.5385],
	["19-09-15", 81.5385, 0.5897],
	["19-09-22", 76.2051, 0.5897],
	["19-09-29", 75.6154, 0.6154],
	["19-10-06", 87, 0.641],
	["19-10-13", 71.7949, 0.6923],
	["19-10-20", 76.5385, 0.6667],
	["19-10-27", 82.1538, 0.6667],
	["19-11-03", 90.5641, 0.6667],
	["19-11-10", 90.9231, 0.6154],
	["19-11-17", 77.0513, 0.6154],
	["19-11-24", 73.641, 0.6667],
	["19-12-01", 78.8718, 0.6154],
	["19-12-08", 85.7949, 0.6154],
	["19-12-15", 83.0513, 0.6667],
	["19-12-22", 76.0513, 0.641],
	["19-12-29", 75.7179, 0.6923],
	["20-01-05", 72.5128, 0.6667],
	["20-01-12", 72.1538, 0.6923],
	["20-01-19", 63.5641, 0.6667],
	["20-01-26", 73.9487, 0.6923],
	["20-02-02", 68.6154, 0.641],
	["20-02-09", 80.9744, 0.6667],
	["20-02-16", 72.5641, 0.6667],
	["20-02-23", 55.4872, 0.6667],
	["20-03-01", 63.4872, 0.6667],
	["20-03-08", 67.8974, 0.7179],
	["20-03-15", 61.3077, 0.641],
	["20-03-22", 65.6923, 0.7179],
	["20-03-29", 62, 0.6667],
	["20-04-05", 66.1538, 0.6923],
	["20-04-12", 71.8718, 0.7179],
	["20-04-19", 55.6667, 0.6667],
	["20-04-26", 73.0256, 0.7436],
	["20-05-03", 69.7692, 0.641],
	["20-05-10", 63.9744, 0.5897],
	["20-05-17", 58.7179, 0.5641],
	["20-05-24", 56.3077, 0.5128],
	["20-05-31", 63.3077, 0.5641],
	["20-06-07", 84.7692, 0.5128],
	["20-06-14", 82.9231, 0.5897],
	["20-06-21", 76.641, 0.5641],
	["20-06-28", 76.4103, 0.6154],
	["20-07-05", 65.9231, 0.6154],
	["20-07-12", 63.3333, 0.641],
	["20-07-19", 68.5385, 0.6667],
	["20-07-26", 68.7436, 0.641],
	["20-08-02", 64.0256, 0.6154],
	["20-08-09", 67.0256, 0.7179],
	["20-08-16", 64.7692, 0.7179],
	["20-08-23", 50.6923, 0.641],
	["20-08-30", 54.5128, 0.7179],
	["20-09-06", 71.6154, 0.7179],
	["20-09-13", 78.5897, 0.6923],
	["20-09-20", 81.2308, 0.6923],
	["20-09-27", 77.2308, 0.641],
	["20-10-04", 99.6667, 0.6667],
	["20-10-11", 106.3333, 0.6923],
	["20-10-18", 99.1795, 0.6923],
	["20-10-25", 92.6154, 0.641],
	["20-11-01", 98.3846, 0.6923],
	["20-11-08", 96.0513, 0.6923],
	["20-11-15", 92.8462, 0.6667],
	["20-11-22", 73.641, 0.6667],
	["20-11-29", 66.2821, 0.6667],
	["20-12-06", 84.7436, 0.7179],
	["20-12-13", 76.2051, 0.6923],
	["20-12-20", 63.7692, 0.641],
	["20-12-27", 48.4103, 0.6154],
	["21-01-03", 57.2821, 0.5897],
	["21-01-10", 62.5641, 0.7179],
	["21-01-17", 52.7692, 0.641],
	["21-01-24", 68.2564, 0.6667],
	["21-01-31", 67.1795, 0.7179],
	["21-02-07", 64.1538, 0.641],
	["21-02-14", 66.9231, 0.7179],
	["21-02-21", 82.0513, 0.7436],
	["21-02-28", 87.0513, 0.6923],
	["21-03-07", 97.0513, 0.7436],
	["21-03-14", 121.0513, 0.7436],
	["21-03-21", 112.9231, 0.7179],
	["21-03-28", 124.3333, 0.8205],
	["21-04-04", 124.1538, 0.7949],
	["21-04-11", 99.0256, 0.7179],
	["21-04-18", 105.6667, 0.7179],
	["21-04-25", 101.1538, 0.7949],
	["21-05-02", 105.0769, 0.7692],
	["21-05-09", 113.1026, 0.7436],
	["21-05-16", 116.359, 0.7179],
	["21-05-23", 98.9744, 0.7436],
	["21-05-30", 108.0256, 0.7179],
	["21-06-06", 119.4359, 0.7179],
	["21-06-13", 119.1538, 0.6923],
	["21-06-20", 111.7949, 0.641],
	["21-06-27", 104.641, 0.6923],
	["21-07-04", 97.4615, 0.7179],
	["21-07-11", 88.3333, 0.6154],
	["21-07-18", 87.3077, 0.641],
	["21-07-25", 76.0256, 0.7179],
	["21-08-01", 70.359, 0.7179],
	["21-08-08", 74.0769, 0.7692],
	["21-08-15", 79.0769, 0.8205],
	["21-08-22", 81.7179, 0.7692],
	["21-08-29", 95.6667, 0.7949],
	["21-09-05", 102, 0.7179],
	["21-09-12", 105.1795, 0.7436],
	["21-09-19", 116.4615, 0.7949],
	["21-09-26", 126.359, 0.7949],
	["21-10-03", 138.1026, 0.8205],
	["21-10-10", 138.0769, 0.7692],
	["21-10-17", 120.4615, 0.7179],
	["21-10-24", 123.2051, 0.7179],
	["21-10-31", 109.7179, 0.7179],
	["21-11-07", 113.1026, 0.6923],
	["21-11-14", 109.3077, 0.6923],
	["21-11-21", 101.9744, 0.6923],
	["21-11-28", 95.359, 0.6667],
	["21-12-05", 95.6667, 0.6667],
	["21-12-12", 97.0769, 0.6923],
	["21-12-19", 90.3077, 0.6667],
	["21-12-26", 79.4872, 0.6667],
	["22-01-02", 84.4872, 0.7692],
	["22-01-09", 80.3846, 0.6667],
	["22-01-16", 82.1795, 0.6923],
	["22-01-23", 75.1282, 0.6667],
	["22-01-30", 87.5128, 0.7179],
	["22-02-06", 95.359, 0.7949],
	["22-02-13", 93.9744, 0.7436],
	["22-02-20", 93.4359, 0.7436],
	["22-02-27", 113.0769, 0.7949],
	["22-03-06", 116.6154, 0.8205],
	["22-03-13", 131.8462, 0.7692],
	["22-03-20", 130.6923, 0.7949],
	["22-03-27", 143.1026, 0.7692],
	["22-04-03", 149.0513, 0.8205],
	["22-04-10", 138.6923, 0.7949],
	["22-04-17", 146.6923, 0.7949],
	["22-04-24", 147.4103, 0.7949],
	["22-05-01", 144.8974, 0.7436],
	["22-05-08", 150.6154, 0.7436],
	["22-05-15", 160.2051, 0.7949],
	["22-05-22", 158.9231, 0.7436],
	["22-05-29", 138.2051, 0.7436],
	["22-06-05", 136.9744, 0.7692],
	["22-06-12", 146.2564, 0.7436],
	["22-06-19", 155.0256, 0.7179],
	["22-06-26", 147.8205, 0.7436],
	["22-07-03", 150.0769, 0.7179],
	["22-07-10", 129.1538, 0.7179],
	["22-07-17", 128.1026, 0.7692],
	["22-07-24", 112.0769, 0.7179],
	["22-07-31", 119.1538, 0.7949],
	["22-08-07", 108.8718, 0.7949],
	["22-08-14", 98.7949, 0.7949],
	["22-08-21", 105.0256, 0.7949],
	["22-08-28", 116.8718, 0.8205],
	["22-09-04", 107.641, 0.7949],
	["22-09-11", 128.5128, 0.7949],
	["22-09-18", 132.4872, 0.7949],
	["22-09-25", 147.1795, 0.8205],
	["22-10-02", 160.2564, 0.7949],
	["22-10-09", 165.1282, 0.7949],
	["22-10-16", 141.2821, 0.7692],
	["22-10-23", 129.9487, 0.7692],
	["22-10-30", 133, 0.7436],
	["22-11-06", 127.9487, 0.7692],
	["22-11-13", 120.3333, 0.6667],
	["22-11-20", 125.0256, 0.7179],
	["22-11-27", 116.2051, 0.6667],
	["22-12-04", 112.9744, 0.7179],
	["22-12-11", 115.9487, 0.6923],
	["22-12-18", 108.1282, 0.6923],
	["22-12-25", 93.8205, 0.7179],
	["23-01-01", 93.8718, 0.7436],
	["23-01-08", 101.6667, 0.7436],
	["23-01-15", 95.8718, 0.7692],
	["23-01-22", 93.5641, 0.7179],
	["23-01-29", 107.3077, 0.7692],
	["23-02-05", 133.641, 0.7949],
	["23-02-12", 145.4103, 0.8462],
	["23-02-19", 142.7949, 0.8205],
	["23-02-26", 156.3077, 0.8205],
	["23-03-05", 171.1282, 0.8205],
	["23-03-12", 154.0513, 0.8462],
	["23-03-19", 174.641, 0.8462],
	["23-03-26", 168.5128, 0.8205],
	["23-04-02", 139.8205, 0.7949],
	["23-04-09", 145.7179, 0.8205],
	["23-04-16", 135.1538, 0.7949],
	["23-04-23", 122.8462, 0.8462],
	["23-04-30", 133.9744, 0.7436],
	["23-05-07", 140.4615, 0.7692],
	["23-05-14", 147.9744, 0.7692],
	["23-05-21", 142.3846, 0.7436],
	["23-05-28", 133.5897, 0.7179],
	["23-06-04", 127.8718, 0.6923],
	["23-06-11", 143.2564, 0.7436],
	["23-06-18", 149.8718, 0.7436],
	["23-06-25", 137.0256, 0.7949],
	["23-07-02", 132.7692, 0.7179],
	["23-07-09", 124.3077, 0.7949],
	["23-07-16", 114.4615, 0.6923],
	["23-07-23", 107.3077, 0.7179],
	["23-07-30", 105.7692, 0.7949],
	["23-08-06", 103.0513, 0.7436],
	["23-08-13", 105.7949, 0.7692],
	["23-08-20", 103.8718, 0.7949],
	["23-08-27", 106.1026, 0.7949],
	["23-09-03", 115.4872, 0.8205],
	["23-09-10", 114.2564, 0.7949],
	["23-09-17", 120.1795, 0.8205],
	["23-09-24", 106.1795, 0.8205],
	["23-10-01", 149.5897, 0.8205],
	["23-10-08", 133.3333, 0.8205],
	["23-10-15", 115.0513, 0.8462],
	["23-10-22", 105.4359, 0.7949],
	["23-10-29", 111.2821, 0.8205],
	["23-11-05", 112.2051, 0.7179],
	["23-11-12", 106.7949, 0.7179],
	["23-11-19", 94.7692, 0.7179],
	["23-11-26", 88.8718, 0.6923],
	["23-12-03", 81.8718, 0.7436],
	["23-12-10", 87, 0.6923],
	["23-12-17", 82.5385, 0.6923],
	["23-12-24", 82, 0.7692],
	["23-12-31", 91.3333, 0.7692],
	["24-01-07", 84.3077, 0.7949],
	["24-01-14", 84.1282, 0.7949],
	["24-01-21", 74.7436, 0.6923],
	["24-01-28", 83.2308, 0.7692],
	["24-02-04", 77.4103, 0.7949],
	["24-02-11", 110.4103, 0.8205],
	["24-02-18", 107.0769, 0.7949],
	["24-02-25", 107.6667, 0.8205],
	["24-03-03", 105.9231, 0.8205],
	["24-03-10", 117, 0.7949],
	["24-03-17", 131.7949, 0.8718],
	["24-03-24", 131.1282, 0.7949],
	["24-03-31", 133.6154, 0.7949],
	["24-04-07", 148.9487, 0.7949],
	["24-04-14", 130.2821, 0.7692],
	["24-04-21", 110.2821, 0.7692],
	["24-04-28", 127.4359, 0.7692],
	["24-05-05", 117.6923, 0.7949],
	["24-05-12", 133.5385, 0.7692],
	["24-05-19", 129.5641, 0.7436]
];

const processedData = rawData.map(([date, value1, value2]) => ({
  date,
  '성장강도A': value1,
  '성장강도B': value2
}));

const GrowthChart: React.FC = () => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={processedData}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
		style={{ backgroundColor: 'transparent' }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#333" />
        <XAxis 
          dataKey="date" 
          stroke="#fff"
          tick={{ fill: '#fff', fontSize: '12px' }}
        />
        <YAxis 
          yAxisId="left" 
          stroke="#fff"
          tick={{ fill: '#fff', fontSize: '12px' }}
        />
        <YAxis 
          yAxisId="right" 
          orientation="right" 
          domain={[0, 1]} 
          stroke="#fff"
          tick={{ fill: '#fff', fontSize: '12px'}}
        />
        <Tooltip 
          contentStyle={{ backgroundColor: '#333', border: 'none' }}
          labelStyle={{ color: '#fff' }}
        />
        <Legend 
          wrapperStyle={{ color: '#fff' }}
        />
        <Line 
          yAxisId="left" 
          type="monotone" 
          dataKey="성장강도A" 
          stroke="#7cc7e8" 
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 8 }}
        />
        <Line 
          yAxisId="right" 
          type="monotone" 
          dataKey="성장강도B" 
          stroke="#ff9b00" 
          strokeWidth={2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GrowthChart;