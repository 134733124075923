import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import logo from './logo.svg';
import './App.css';
import { Helmet } from 'react-helmet';
import Sidebar from './Sidebar';
import Home from './pages/Home';
import Brand from './pages/Brand';
import Product from './pages/Product';
import Category from './pages/Category';
import Company from './pages/Company';
import 'ag-grid-community/styles/ag-grid.css';
import './style/GridTheme.css';
import BrandDetail from './pages/BrandDetail';
function App() {
  return (
    <Router>
    <div className="App">
      <Helmet>
        <title>Trend Scout</title>
      </Helmet>
      <Sidebar />
      <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/brand" element={<Brand />} />
            <Route path="/brand_detail" element={<BrandDetail />} />
            <Route path="/product" element={<Product />} />
            <Route path="/category" element={<Category />} />
            <Route path="/company" element={<Company />} />
          </Routes>
        </div>
    </div>
    </Router>
  );
}

export default App;
