  import React from 'react';

  function Product() {
    return (
      <div>
        <h1>Product</h1>
      </div>
    );
  }

  export default Product;
