import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import BrandIcon from './components/BrandIcon';
import ProductIcon from './components/ProductIcon';
import CategoryIcon from './components/CategoryIcon';
import CompanyIcon from './components/CompanyIcon';
const { Title } = Typography;

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 750);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 750);

  useEffect(() => {
    const handleResize = () => {
      const wideScreen = window.innerWidth > 750;
      setIsWideScreen(wideScreen);
      setIsOpen(wideScreen);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
	<>
	{!isOpen && !isWideScreen && (
        <img src="/logo.png" alt="로고" className="logo-closed" style={{ height: '25px', width: 'auto' , margin: '10px' }} />
      )}
    <div className={`sidebar ${isOpen ? 'open' : ''} ${isWideScreen ? 'wide-screen' : ''}`}>
	<img src="/logo.png" alt="로고" className="logo-closed" style={{ height: 'auto', width: '180px' , margin: '10px' }} />
      {!isWideScreen && (
        <button onClick={() => setIsOpen(!isOpen)} style={{ marginTop: '50px' }}>
          {isOpen ? '◀︎' : '▶︎'}
        </button>
      )}
      <ul>
        <li><Link to="/brand"><BrandIcon/> 브랜드 분석</Link></li>
        <li><Link to="/product"><ProductIcon/> 상품 분석</Link></li>
        <li><Link to="/category"> <CategoryIcon/>카테고리 분석</Link></li>
        <li><Link to="/company"> <CompanyIcon/>기업 분석</Link></li>
      </ul>
    </div>
	</>
  );
};

export default Sidebar;