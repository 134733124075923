import React from 'react';
import '../common/Page.css';
import { Typography, Divider, Row, Col } from 'antd';


import BrandIcon from '../components/BrandIcon';
import GrowthChart from '../components/GrowthChart';
import ProductItem from '../components/ProductItem';

const { Title } = Typography;

function BrandDetail() {
  return (
    <div>
      <Title><BrandIcon/> 브랜드 분석</Title>
      <Title level={2}>vt cosmetics</Title>
      <Title level={5} style={{ letterSpacing: '0.05em', wordSpacing: '0.2em' }}>
        AMAZON US | 검색량 1,121 | WoW +5% | MoM +10% | YoY +30%
      </Title>

      <Title level={3}>트랜드 차트</Title>
        <GrowthChart />      
      <Divider />

      <Title level={3}>브랜드 비교</Title>
          
      <Divider />

      <Title level={3}>대표 상품</Title>
        
      <Divider />

      
      <div style={{ display: 'flex', flexWrap: 'wrap',gap: '16px'}}>
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
        <ProductItem />
      </div>



    </div>
  );
}

export default BrandDetail;
