import React from 'react';
import '../common/Page.css';

import { Typography } from 'antd';
const { Title } = Typography;
function Home() {
  return (  
    <>
      <Title>Home</Title>
    </>
  );
}

export default Home;
