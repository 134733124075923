import React from 'react';

function Category() {
  return (
    <div>
      <h1>Category</h1>
    </div>
  );
}

export default Category;
