import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridReadyEvent } from 'ag-grid-community';

interface MyGridProps {
  data: any[];
  onRowClicked?: (data: any) => void;
}

const MyGrid: React.FC<MyGridProps> = ({ data, onRowClicked }) => {
  const gridRef = useRef<AgGridReact>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [gridSize, setGridSize] = useState({ width: '100%', height: '100%'});

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: false,
    resizable: true,
  }), []);

  useEffect(() => {
    if (data && data.length > 0) {
      const sample = data[0];
      const newColumnDefs: ColDef[] = Object.keys(sample).map(key => ({
        field: key,
        headerName: key,
      }));
      setColumnDefs(newColumnDefs);
    }
  }, [data]);

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setGridSize({ width: `${width}px`, height: `${height}px` });
      }
    };

    const resizeObserver = new ResizeObserver(updateSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    updateSize(); // 초기 크기 설정

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    // 약간의 지연 후 크기 조정
    setTimeout(() => {
		params.api.sizeColumnsToFit();
	  }, 100);
  };

  return (
    <div ref={containerRef} style={{ width: '100%'}}>
      <div className="ag-theme-material-dark" style={{...gridSize, border: '1px solid #3a434e', borderRadius: '10px !important'}}>
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
		  domLayout="autoHeight" 
        />
      </div>
    </div>
  );
};

export default MyGrid;