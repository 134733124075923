import { Typography} from 'antd';
import MyGrid from './MyGrid';
const { Title } = Typography;

const sampleData = [
	{ '키워드': 'anua', '플랫폼': 'AMAZON US', '전주대비(%)': '+5', '전월대비(%)': '+10'},
	{ '키워드': 'anua', '플랫폼': 'AMAZON US', '전주대비(%)': '+5', '전월대비(%)': '+10' },
	{ '키워드': 'anua', '플랫폼': 'AMAZON US', '전주대비(%)': '+5', '전월대비(%)': '+10' },
];	
interface GroupKeywordProps {
	_title?: string;
  }


const GroupKeyword: React.FC<GroupKeywordProps> = ({ _title }) => {
	const title = _title || '그룹 키워드';

	return (
	  <>
	  <div style={{border: '1px solid #3a434e', borderRadius: '10px',padding: '10px'}}>
		<Title level={4} style={{ letterSpacing: '0.05em', wordSpacing: '0.2em', margin:'8px 0px' }}>{title}</Title>
		<MyGrid data={sampleData} />
	  </div>
	  </>
	);
  }
  
  export default GroupKeyword;