import React from 'react';
import '../common/Page.css';
import { Typography, Divider, Col, Row, Select, Button, Input } from 'antd';
import { BorderlessTableOutlined, FieldTimeOutlined, KubernetesOutlined, ThunderboltFilled } from '@ant-design/icons';
import BrandIcon from '../components/BrandIcon';
import MyGrid from '../components/MyGrid';
import GroupKeyword from '../components/GroupKeyword';
import { useNavigate } from 'react-router-dom';


const { Title } = Typography;

const sampleData = [
  { 키워드: '아누아', 플랫폼: 'AMAZON US', 분석일: '2024-10-04', 상태: '분석중' },
  { 키워드: 'skin1004', 플랫폼: 'AMAZON US', 분석일: '2024-10-04', 상태: '완료' },
  { 키워드: 'buldak', 플랫폼: 'AMAZON US', 분석일: '2024-10-04', 상태: '완료' },
  { 키워드: '스노우피크', 플랫폼: 'AMAZON US', 분석일: '2024-10-04', 상태: '완료' },
  { 키워드: '레인부츠', 플랫폼: 'NAVER', 분석일: '2024-10-04', 상태: '완료' }
];

function Brand() {
  const navigate = useNavigate();
  const handleChange = (value: string) => {
    console.log(`선택된 값: ${value}`);
  };

  const handleRowClicked = (data: any) => {
    console.log('선택된 제품:', data.제품명);
    navigate('/brand_detail');
  };

  return (
    <div>
      <Title><BrandIcon /> 브랜드 분석</Title>

      <Row gutter={[16, 16]}>
        <Col sm={14} xs={24}>
          <Input style={{ width: '100%', fontSize: '16px', height: '100%' }} />
        </Col>
        <Col sm={6} xs={24}>
          <Select
            defaultValue="AMAZON US"
            onChange={handleChange}
            style={{ width: '100%', height: '100%', fontSize: '16px' }}
          >
            <Select.Option value="AMAZON US">AMAZON US</Select.Option>
            <Select.Option value="AMAZON JP">AMAZON JP</Select.Option>
            <Select.Option value="AMAZON MX">AMAZON MX</Select.Option>
          </Select>
        </Col>
        <Col sm={4} xs={24}>
          <Button style={{ width: '100%', fontSize: '16px', padding: '20px 0px' }}>
            <ThunderboltFilled style={{ color: 'yellow' }} />분석하기
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[16, 16]}>
        <Col sm={10} xs={24}>
          <Title level={4} style={{ letterSpacing: '0.05em', wordSpacing: '0.2em' }}>
            <FieldTimeOutlined /> 최근 분석 키워드
          </Title>
          <MyGrid data={sampleData} onRowClicked={handleRowClicked} />
        </Col>
        <Col sm={7} xs={24}>
          <Title level={4} style={{ letterSpacing: '0.05em', wordSpacing: '0.2em' }}>
            <KubernetesOutlined /> 추적 키워드
          </Title>
          <MyGrid data={sampleData} onRowClicked={handleRowClicked} />
        </Col>
        <Col sm={7} xs={24}>
          <Title level={4} style={{ letterSpacing: '0.05em', wordSpacing: '0.2em' }}>
            <BorderlessTableOutlined /> 추적 해시태그
          </Title>
          <MyGrid data={sampleData} onRowClicked={handleRowClicked} />
        </Col>
      </Row>

      <Divider />


      <Row gutter={[16, 16]}>
        <Col sm={8} xs={24}>
          <GroupKeyword _title="뷰티" />
        </Col>
        <Col sm={8} xs={24}>
          <GroupKeyword _title="뷰티" />
        </Col>
        <Col sm={8} xs={24}>
          <GroupKeyword _title="뷰티" />
        </Col>
      </Row>
    </div>
  );
}

export default Brand;