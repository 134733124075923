import { Button, Card, Typography} from 'antd';
import StarIcon, { StarIconType } from './stars/StarIcon';
import Column from 'antd/es/table/Column';
const { Title, Paragraph } = Typography;

function ProductItem() {
	return (
	  <Card bordered={true} style={{width: '200px'}}>
		<img src="/product_sample_img.jpg" alt="product" width={150} height={150} />
		<Title level={4} style={{letterSpacing: '0.05em', wordSpacing: '0.2em'}}>
			samyang
		</Title>
		<Paragraph
			ellipsis={{
			rows: 3,
			expandable: false,
			}}
			style={{
			margin: 0,
			fontSize: '16px'  // Title level={5}와 비슷한 크기
			}}
		>
			Samyang Buldak Spicy Ramen, Hot Chicken Ramen, Korean Stir-Fried Instant Noodle, Original, 1 Bag with 5 Pack
		</Paragraph>			

		<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
			<StarIcon type={StarIconType.FULL} />
			<StarIcon type={StarIconType.FULL} />
			<StarIcon type={StarIconType.FULL} />
			<StarIcon type={StarIconType.FULL} />
			<StarIcon type={StarIconType.FULL} />
			<Title level={5} style={{margin:0}}>12,900</Title>
		</div>

		<Button type="primary" style={{width: '100%'}}>분석하기</Button>
		
		</Card>
	);
  }
  
  export default ProductItem;