import React from 'react';

function Company() {
  return (
    <div>
      <h1>Company</h1>
    </div>
  );
}

export default Company;
